export const config = {
  BASE_URL: 'https://api.welbee.co.uk/api',
  ADMIN_URL: 'https://api.admin.welbee.co.uk/api',
  APP_URL: 'https://localhost:3000/',
  GOOGLE_ANALYTICS_KEY: 'UA-146282804-3',
  AMPLITUDE_KEY: '4aa8ea4236ad42aecb06d8c84ca62a26',
  HOTJAR: {
    hjid: '1279102',
    hjsv: '6',
  },
};
