import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { amplitudeLog } from '../../utils/CommonFunctions';
import {
  AppButton,
  LoginFormWrapper,
  ErrorWrapper,
  WelbeeTextField,
} from '../../resources/styling/appStyle';
import { loginNormalEye, loginShowEye } from '../../resources/images/index';
import Grid from '@mui/material/Grid';
import { colors } from '../../resources/theme/colors';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { accountService, storageService } from '../../services';
import { routesObj } from '../../routes';
import { loaderAction } from '../../store/loaderStore';
import { firstLoginAction } from '../../store/userStore';
import {
  WebStorageNames,
  Roles,
  toggleOption,
  HeadTeacherAccess,
  WelbeeTypes,
} from '../../utils/Constants';
import { parseJwt } from '../../utils/CommonFunctions';
import { Redirect } from 'react-router-dom';
import LoginWrapper from '../../components/common/LoginWrapper';
import { WrapperMain } from '../../resources/styling/formStyle';
import {
  LoginFormInner,
  InputWrapper,
  FieldWrapper,
  PasswordViewIcon,
} from '../../components/common/loginStyle';
import i18next from 'i18next';
import { usePermission } from 'react-permission-role';
import { signinRedirect } from '../../services/api/userService';
import { useSelector } from 'react-redux';
import { welbeeLogo } from '../../resources/images';
import { CircularProgress } from '@mui/material';
import { Loader } from '../../components/common/loginStyle';

const { mandy } = colors;

function Login(props) {
  let [isPasswordShow, setIsPasswordShow] = useState(true);
  let [emailErrorMessage, setEmailErrorMessage] = useState('');
  let [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  let [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const { setUser } = usePermission();

  const user = useSelector(state => state.auth.user);

  const getRegions = () => {
    accountService
      .checkCurrentRegion()
      .then((response) => {
        storageService.setItem(WebStorageNames.UsRegion, response.data);
        i18next.changeLanguage(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    debugger;
    if (!user) {
      signinRedirect();
    }

    if(user){
      loadHomePage(user);
    }

    getRegions();
    amplitudeLog('PageView', 'Login', '');
  }, []);

  const loadHomePage = (user) => {
    accountService.login(user).then((response) => {
      const { success, message } = response;
      props.loaderActions.dataLoading(false);
      if (success) {
        storageService.setToken({token: response.data.access_token});
        storageService.setItem( WebStorageNames.IsDisplayMatHeatMap, response.data.IsDisplayMatHeatMap );
        storageService.setItem( WebStorageNames.SubscriptionId, response.data.SubscriptionId );

        const userInfo = parseJwt(response.data.access_token);
        
        storageService.setItem( WebStorageNames.UserInfo, response.data.userInfo );
        storageService.setItem( WebStorageNames.SelectedTab, response.data.SubscriptionId === WelbeeTypes.WelbeeVoiceFree 
          || response.data.SubscriptionId === WelbeeTypes.WelbeeVoicePro ? toggleOption.WelbeeVoice : toggleOption.WelbeeSurvey);

        storageService.setItem( WebStorageNames.SchoolId, response.data.schoolId );
        storageService.setItem( WebStorageNames.SchoolName, response.data.schoolName );
        const token = storageService.getItem(WebStorageNames.Token);
        
        if (!token) {
          return <Redirect to={routesObj.Login.path} />;
        }

        // Settings permissions
        setUser({
          id: userInfo?.id,
          roles: [userInfo?.role],
          permissions: response?.data?.Permissions
        });

        storageService.setItem( WebStorageNames.AccessLevel, response.data.AccessLevel );
        storageService.setItem( WebStorageNames.IsStaff, response.data.IsStaff );
        storageService.setItem( WebStorageNames.WelbeeVoiceAccountType, response.data.WelbeeVoiceAccountType );
        storageService.setItem( WebStorageNames.IsFirstLogin, response.data.IsFirstLogin );
        storageService.setItem( WebStorageNames.IsVoiceFirstLogin, response.data.IsVoiceFirstLogin );
        storageService.setItem( WebStorageNames.FreeTrialEndDate, response.data.FreeTrialEndDate );
        storageService.setItem( WebStorageNames.SelectedTab, response.data.SubscriptionId === WelbeeTypes.WelbeeVoiceFree || 
          response.data.SubscriptionId === WelbeeTypes.WelbeeVoicePro ? toggleOption.WelbeeVoice : toggleOption.WelbeeSurvey);
        storageService.setItem( WebStorageNames.IsUpgradeDisplay, response.data.IsUpgradeDisplay );
        storageService.setItem( WebStorageNames.IsUpgradBannderDisplayed, false );
        
        props.history.push(routesObj.SuperDashboard.path);
      }
    })
    .catch((error) => console.log(error));
  }; 

  return (
    <Formik
      initialValues={{
        Email: '',
        Password: '',
      }}
      onSubmit={(values, actions) => {
        
      }}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={Yup.object().shape({
      })}
      render={({ values, handleChange, touched, errors, handleSubmit }) => (
        <Loader>
          <div className="loading">
            <div className="login-logo">
              <img src={welbeeLogo} alt="" />
            </div>
            <div>
              <svg width={0} height={0}>
                <defs>
                  <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" stopColor="#e01cd5" />
                    <stop offset="100%" stopColor="#1CB5E0" />
                  </linearGradient>
                </defs>
              </svg>
              <CircularProgress sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }} size={50} />
            </div>        
          </div>
        </Loader>
      )}
    />
  );
}

const mapDispatchToProps = (dispatch) => ({
  loaderActions: bindActionCreators(loaderAction, dispatch),
  userAction: bindActionCreators(firstLoginAction, dispatch),
});
export default connect(null, mapDispatchToProps)(withRouter(Login));
